import { Link, useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
/**
 * Represents the booking confirmation component.
 * @component
 * @returns {JSX.Element} The booking confirmation component.
 */
const APP_PATH = process.env.REACT_APP_PATH;
const BookingFailed = () => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const contentToPrint = useRef(null);

  /**
   * Handles the print event.
   * @function
   * @returns {void}
   */

  // Set booking details from location state passed from the previous page(checkout page)
  // useEffect(() => {
  //   if (location.state) {
  //     const { bookingDetails } = location.state.confirmationData;
  //     setBookingDetails(bookingDetails);
  //   } else {
  //     navigate('/');
  //   }
  // }, [bookingDetails, location.state, navigate]);
  useEffect(() => {
    handleLanguageChange(lang);
  });
  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  return (
    <div className="md:mx-auto max-w-[800px] my-40 cus_shadow red rounded-md">
      <div
        ref={contentToPrint}
        className="flex mx-2  px-4 py-12 items-center justify-center flex-col "
      >
        <h1 className="text-[red] text-[80px] font-bold">
          {t('booking.Failed')}
        </h1>
        <p className="text-[#000] font-medium">{t('booking.BookingFailed')}</p>
        <p className="text-[#000] font-medium">{t('booking.PleaseTryAgain')}</p>
        <FontAwesomeIcon
          icon={faXmark}
          className="text-[red] w-[200px] h-[200px] ml-3"
        />
        <Link
          to={`${APP_PATH}Account/Login`}
          className={`border-[2px] border-[#fff] px-10 py-2 mt-10 bg-[red] text-[20px] text-[#fff] font-medium rounded-[30px]`}
        >
          {t('navbar.login')}
        </Link>
      </div>
    </div>
  );
};
export default BookingFailed;
